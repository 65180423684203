.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.receipt-total {
  font-weight: 300;
  font-size: 32;
  font-family: "Inter";
}

#container-circles {
  position: absolute;
  left: 50%;
  bottom: 130px;
}

#outer-circle {
  position: absolute;
  left: -37px;

  height: 75px;
  width: 75px;

  /*
    opacity of 0.4
  */
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 50%;

  z-index: 1;
}

#inner-circle {
  position: absolute;
  left: 50%;
  top: 38px;

  height: 44px;
  width: 44px;

  background: white;
  border-radius: 50%;

  /*
   Offset the position correctly with
   minus half of the width and minus half of the height
  */
  margin: -22px 0px 0px -22px;

  /*
    On the top of outer-circle
  */
  z-index: 2;
}

#inner-circle.is-clicked {
  height: 38px;
  width: 38px;
  margin: -19px 0px 0px -19px;
}

.react-html5-camera-photo {
  position: relative;
  text-align: center;
}

.react-html5-camera-photo > video {
  width: 768px;
}

.react-html5-camera-photo > img {
  width: 768px;
}

.react-html5-camera-photo > .display-error {
  width: 768px;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .react-html5-camera-photo > video,
  .react-html5-camera-photo > img {
    width: 100%;
  }
  .react-html5-camera-photo > .display-error {
    width: 100%;
  }
}

/* fullscreen enable by props */
.react-html5-camera-photo-fullscreen > video,
.react-html5-camera-photo-fullscreen > img {
  width: 100vw;
  height: 75vh;
}
.react-html5-camera-photo-fullscreen > video {
  object-fit: fill;
}
.react-html5-camera-photo-fullscreen > .display-error {
  width: 100vw;
  height: 100vh;
}

.item-container {
  display: flex;
  justify-content: space-between;
  padding: 0px 16px;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
  padding: 4px 16px;
  align-items: center;
}

.fixed-actions-container {
  position: fixed;
  bottom: 0;
  background-color: #070a2c;
  width: 100%;
  color: white;
  padding: 0px 0px;
  font-family: "Roboto";
}

.dense-flex-container {
  display: flex;
  height: 100%;
}

.btn-square {
  min-width: 48px;
  height: 48px;
  color: white;
  border: none;
  font-size: 16px;
  margin-left: 0;
  margin-right: 0;
}

.black-square {
  min-width: 48px;
  height: 48px;
  background-color: #070a2c;
  color: white;
}

.btn-square-selected {
  min-width: 48px;
  height: 48px;
  background-color: #00ffc2;
  color: #070a2c;
  border: none;
  font-size: 16px;
  margin-left: 0;
  margin-right: 0;
}

.title-sm {
  font-weight: 300;
  font-size: 16px;
  text-align: left;
}

.body-small {
  font-weight: 500;
  font-size: 10px;
  text-align: left;
}

.btn-add {
  flex-basis: 135px;
  padding: 0px 8px;
  background-color: white;
  color: black;
  border: none;
  border-bottom: 1px solid black;
  text-decoration: none;
}

.div-add {
  flex-basis: 135px;
  background-color: black;
  color: white;
  border: none;
  border-bottom: 1px solid white;
  text-decoration: none;
  padding: 12px 0px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.btn-capsule {
  border-radius: 16px;
  border: none;
  height: 24px;
  min-width: 24px;
  font-size: 10px;
  padding: 4px 2px;
  background-color: white;
  color: black;
}

.font-tiny {
  font-size: 8px;
  font-weight: 300;
}

.react-tabs__tab--selected {
  font-weight: 700;
  text-decoration: underline;
}
